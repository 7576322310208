export default {
    email: 'Email',
    password: 'Password',
    passwordlessLogin: 'Send me a magic link over email to login',
    oneTimePassword: 'One-time password',
    repeatPassword: 'Repeat password',
    name: 'Name',
    actions: 'Actions',
    featureFlags: 'Customers will have access to the following features:',
    isActive: 'Is active',
    reportType: 'Report type',
    delete: 'Delete',
    description: 'Description',
    expirationDate: 'Expiration date',
    url: 'URL',
    secret: 'Secret',
    componentType: 'Component type',
    policy: 'Policy',
    group: 'Group',
    commandExpiry: 'Command expiry',
    newDevicePassword: 'New device password',
    devicePasswordResetFlags: 'Device password reset flags',
    addCsvFile: 'Add CSV file',
    csvFile: 'CSV',
    setExpiration: 'Set an expiration for the token',
    expiresIn: 'Expires in',
    provisioningLocale: 'Provisioning locale',
    preserveSystemApps: 'Preserve system apps',
    allowPersonalUsage: 'Personal usage',
    oneTimeUse: 'One-time use',
    useMobileDataForEnrrollment: 'Use mobile data for enrollment',
    ssid: 'SSID',
    securityType: 'Security type',
    wifiNetwork: 'Wi-Fi network',
    country: 'Country',
    timeZone: 'Time zone',
    gender: 'Gender',
    iAgreeTo: 'I agree to',
    androidEnterpriseEmail: 'Android Enterprise email ',
    googlePlayContactEmail: 'Google Play contact email',
    dataProtectionOfficer: 'Data protection officer',
    dataProtectionOfficerEmail: 'Data protection officer email',
    dataProtectionOfficerPhone: 'Data protection officer phone',
    euRepresentative: 'EU representative',
    euRepresentativeEmail: 'EU representative email',
    euRepresentativePhone: 'EU representative phone',
    customizeForSelectedLanguages: 'Customize for selected languages',
    title: 'Title',
    content: 'Content',
    remove: 'Remove',
    displayName: 'Display name',
    slug: 'Slug',
    organizationId: 'Android Enterprise Organisation ID',
    boundEmail: 'Email bound to Android Enterprise',
    resellerId: 'Reseller ID',
    teamId: 'Team ID',
    addNewLogo: 'Add new logo',
    addALogo: 'Add a logo',
    forceTwoFactorMembers: 'Force two-factor authentication for team members',
    companionAppPackageName: 'Companion app package name',
    zeroTouchId: 'Zero-touch ID',
    zeroTouchCustomerId: 'Zero-touch ID',
    stripeKey: 'Stripe key',
    stripeWebhookSecret: 'Stripe webhook secret',
    stripeWebhookSecretHelp: 'We recommend setting this so we receive events to keep your teams up to date',
    resellerDomain: 'Reseller domain',
    pricingUrl: 'Pricing URL',
    termsOfServiceUrl: 'Terms of service URL',
    privacyPolicyUrl: 'Privacy policy URL',
    enable2fa: 'Enable 2FA',
    platform: 'Platform',
    currentPassword: 'Current password',
    newPassword: 'New password',
    repeatNewPassword: 'Repeat new password',
    language: 'Language',
    leave: 'Leave',
    settingName: 'Setting name',
    reason: 'Reason',
    packageName: 'Package name',
    fieldPath: 'Field path',
    currentValue: 'Current value',
    installationFailureReason: 'Installation failure reason',
    managementMode: 'Management mode',
    devicePosture: 'Device posture',
    Ownership: 'Ownership',
    commonCriteriaModeStatus: 'Common Criteria mode status',
    policyCompliant: 'Policy compliant',
    timeOfEnrollment: 'Time of enrollment',
    lastStatusReportTime: 'Last status report time',
    lastPolicySyncTime: 'Last policy sync time',
    deviceIsSecured: 'Device is secured',
    unknownSourcesEnabled: 'Unknown sources enabled',
    devSettingsEnabled: 'Development settings enabled',
    adbEnabled: 'ADB enabled',
    verifyAppsEnabled: 'Verify applications enabled',
    encryptionEnabled: 'Encryption enabled',
    encryptionStatus: 'Encryption status',
    androidVersion: 'Android version',
    androidVersionCode: 'Android device policy version code',
    androidVersionName: 'Android device policy version name',
    androidBuildNumber: 'Android build number',
    deviceKernelVersion: 'Device kernel version',
    bootloaderVersion: 'Bootloader version',
    androidBuildTime: 'Android build time',
    securityPathLevel: 'Security patch level',
    primaryLanguageCode: 'Primary language code',
    deviceBuildSignature: 'Device build signature',
    updateStatus: 'Update status',
    updateReceivedTime: 'Update received time',
    brand: 'Brand',
    hardware: 'Hardware',
    basebandVersion: 'Baseband version',
    manufacturer: 'Manufacturer',
    serialNumber: 'Serial number',
    model: 'Model',
    battery: 'Battery',
    imei: 'IMEI',
    meid: 'MEID',
    wiFiMacAddress: 'Wi-Fi MAC address',
    activeRadio: 'Active radio',
    wifiStrength: 'Wi-Fi strength',
    cellularStrength: 'Cellular strength',
    phoneNumber: 'Phone number',
    contactPhoneNum: 'Contact phone number',
    carrierName: 'Carrier name',
    ram: 'RAM',
    internalStorage: 'Internal storage',
    externalStorage: 'External storage',
    availableStorage: 'Available storage',
    eventType: 'Event type',
    securityPosture: 'Security posture',
    permissionPolicy: 'Permission policy',
    grantType: 'Grant type',
    applicationEnabled: 'Application enabled',
    installType: 'Install type',
    storePackageName: 'Capture Device Insights',
    storePackageNameError: 'Limit exceeded. Only 5 packages are allowed.',
    scopes: 'Scopes',
    appTrackIds: 'App Track IDs',
    connectedWorkAndPersonalApp: 'Connected work & personal app',
    defaultPermissionPolicy: 'Default permission policy',
    autoUpdateMode: 'Auto update mode',
    minimumVersionCode: 'Minimum app version code',
    startTime: 'Start time',
    endTime: 'End time',
    startDate: 'Start date',
    endDate: 'End date',
    selectWiFiNetwork: 'Select Wi-Fi network',
    autoConnect: 'Auto-connect',
    searchThroughComponents: 'Search through your {0} components',
    primaryBrandingColor: 'Primary branding colour',
    activateWebhook: 'Enable webhook',
    changeWebhookStatus: 'Change webhook status',
    highPriority: 'High priority',
    highPriorityHelp: "This means we'll try to wake up the device if it's sleeping",
    ariaLabel: {
        showAllActions: 'Show all actions',
        pickInitialDate: 'Pick initial date',
        pickEndDate: 'Pick end date',
        goToPreviousPage: 'Go to previous page',
        goToNextPage: 'Go to next page',
    },
    devicePossession: {
        state: 'State',
        reason: 'Reason',
    },
    enterpriseId: 'Enterprise ID',
    supportPortalURL: 'Support portal URL',
    lastHeartbeatTime: 'Last heartbeat time',
    onlyBusinessEmail: 'Only allow users with a business email',
    field: 'Field',
    value: 'Value',
    cancelTeamDelete: 'Cancel team deletion',
    webhookEvents: 'Webhook events',
    date: 'Date',
    selectDate: 'Select date',
    fromDate: 'From {0}',
    toDate: 'To {0}',
    fromBeginningOfTimes: 'From beginning of times',
    beginningOfTimes: 'Beginning of times',
    toEndOfTimes: 'To end of times',
    endOfTimes: 'End of times',
    time: 'Time',
    is: 'Is',
    preferredTeam: 'Preferred team',
    specificNonComplianceReason: 'Additional details',
    oncWifiContext: 'GUID of non-compliant Wi-Fi configuration',
    passwordPoliciesContext: 'Non-compliant password applies to',
    type: 'Type',
    beta: 'Beta',
    trialPeriodDays: 'Trial period in days',
    trialPeriodDaysHelp: 'Leave it empty or set to 0 to have no trial period for your customers',
    signupPlan: 'Signup plan',
    freeDevices: 'Free devices',
    resellerFromEmail: 'Platform email address',
    noTrackIds: 'No track IDs',
    enrollmentToken: 'Enrollment token',
    confirmation: 'Confirmation',
    compliancePeriod: 'Compliance period in days',
    compliancePolicy: 'Compliance policy',
    hasOfflineCompliance: 'Set offline compliance policy',
    stripePriceId: 'Stripe price ID',
    appVersionReportPackages: 'Packages for report',
    lostDeviceMessage: 'Lost message',
    streetAddress: 'Street address',
    kbLinks: {
        deviceCSVBulkActionsURL: "Device's bulk actions article URL",
        feedbackFormURL: 'Feedback form support article URL',
    },
    upTime: 'Uptime',
    metadata: 'Metadata',
    installPriority: 'Install priority',
    installConstraint: 'Install constraint',
    networkTypeConstraint: 'Network type constraint',
    chargingConstraint: 'Charging status constraint',
    deviceIdleConstraint: 'Device idle state constraint',
    deviceInsightsTimeInterval: 'Device Insights Time Interval (in seconds)',
    blockAddingAccounts: 'Block adding accounts',
    locationName: 'Location name',
    latitude: 'Latitude',
    longitude: 'Longitude',
    radius: 'Radius',
    errorDialogTitle: 'Error',
    coordinates: 'Coordinates',
    credit: 'Credit',
    expiry: 'Expiry date',
    notes: 'Notes',
};
