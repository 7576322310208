export enum Permissions {
    // Team related permissions for team/reseller
    'Mambo:Team:write' = 0,
    'Mambo:sensitive' = 1,
    'Mambo:Team:webhooks:read' = 5,
    'Mambo:Team:webhooks:write' = 6,
    'Mambo:Team:webhooks:delete' = 7,
    // Membership permissions
    'Mambo:Members:read' = 2,
    'Mambo:Members:write' = 3,
    'Mambo:Members:delete' = 4,
    // Permissions & roles
    'Mambo:Role:read' = 8,
    'Mambo:Role:write' = 9,
    'Mambo:Role:delete' = 10,
    // AMAPI read permissions
    'Mambo:Team:AMAPI:read' = 11,
    // AMAPI Policies and policy components
    'Mambo:Team:Policy:write' = 12,
    'Mambo:Team:Policy:delete' = 13,
    // AMAPI enrollment tokens
    'Mambo:Team:EnrollmentToken:write' = 14,
    'Mambo:Team:EnrollmentToken:delete' = 15,
    // AMAPI devices
    'Mambo:Team:Device:lock' = 16,
    'Mambo:Team:Device:reset-password' = 17,
    'Mambo:Team:Device:reboot' = 18,
    'Mambo:Team:Device:wipe' = 19,
    'Mambo:Team:Device:broadcast-message' = 20,
    'Mambo:Team:Device:clear-app' = 21,
    // Access token permissions
    'Mambo:Access-token:read' = 22,
    'Mambo:Access-token:write' = 23,
    'Mambo:Access-token:delete' = 24,
    // Reports
    'Mambo:Team:Report:read' = 25,
    'Mambo:Team:Report:write' = 26,
    'Mambo:Team:Report:delete' = 27,
    // Billing
    'Mambo:Billing' = 28,
    // Remote Control
    'Mambo:Team:Device:remote-control' = 29,
    // Reseller permissions
    'Mambo:Reseller:write' = 30,
    'Mambo:Reseller:Teams:read' = 31,
    'Mambo:Reseller:Users:read' = 32,
    'Mambo:Reseller:Users:impersonate' = 33,
    /** @deprecated Use `Mambo:sensitive` instead */
    'Mambo:Reseller:sensitive' = 34,
    'Mambo:Reseller:Users:reset-password' = 35,
    'Mambo:Reseller:Users:leave-team' = 36,
    // Posession state variables
    'Mambo:Team:Device:possession-state:read' = 37,
    'Mambo:Team:Device:possession-state:write' = 38,
    'Mambo:Reseller:Teams:billing-admin' = 39,
    'Mambo:Reseller:Teams:delete' = 40,
    // Device Groups
    'Mambo:Team:Group:write' = 41,
    'Mambo:Team:Group:delete' = 42,
}
